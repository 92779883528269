import { j9bcApi } from "@/apis";
import { storage } from "@/utils";
import { makeAutoObservable } from "mobx";

/** 用户相关store */
class MemberStore {
  /** 用户token */
  private _webToken?: string = "";
  private _userInfo = {} as UserInfo;
  private initBalance = {
    USDT: 0,
    SHIB: 0,
    LUNA: 0,
    LTC: 0,
    J9BC: 0,
    DOGE: 0,
    BNB: 0,
    AXS: 0,
  } as Balance;

  private _userBalance = this.initBalance;

  constructor() {
    if (process.env.NODE_ENV === "development") {
      this.webToken = storage.get("token");
    }
    setTimeout(() => {
      this.updateBalance();
    }, 1000);
    makeAutoObservable(this);
  }

  async login(ticket: string) {
    const token = await j9bcApi.getAuthToken({ token: ticket });
    this.webToken = token;
    const [, ..._userinfo] = await Promise.all([
      this.updateBalance(),
      j9bcApi.getUserInfo(),
      j9bcApi.getUserData(),
    ]);
    this.userinfo = Object.assign({}, this.userinfo, ..._userinfo);
    if (process.env.NODE_ENV === "development") {
      storage.set("token", token);
    }
  }

  // 登出reset 用户信息

  logout() {
    this.webToken = undefined;
    this.userinfo = {} as UserInfo;
    this.userBalance = this.initBalance;
  }

  /** 设置用户token */
  set webToken(webToken: string | undefined) {
    this._webToken = webToken;
    // storage.set(TOKEN_CACHE_KEY, webToken);
  }

  /** 是否已登录 */
  get isLoged() {
    return Boolean(this._webToken);
  }
  /** 用户token */
  get webToken() {
    return this._webToken;
  }
  /** 用户信息 */
  get userinfo() {
    return this._userInfo;
  }
  set userinfo(userinfo: UserInfo) {
    this._userInfo = userinfo;
    // storage.set(USER_CACHE_KEY, this._userInfo);
  }
  /** 用户余额/钱包 */
  get userBalance() {
    return this._userBalance;
  }
  set userBalance(userBalance: Balance) {
    this._userBalance = userBalance;
  }
  async updateBalance() {
    if (!this.isLoged) return;

    // j9bc 页面才需要轮询厅内余额
    const isJ9bcUrl = ["/", "/swap"].includes(window.__J9BC__.route);
    if (isJ9bcUrl) {
      this.userBalance = await j9bcApi.getUserWallet();
      setTimeout(() => {
        this.updateBalance();
      }, 3000);
    }
  }
}

export default new MemberStore();
