import BaseApi from "./BaseApi";

// 广告类型(0:买 1：卖),可用值:BUY,SELL
export enum ETradeType {
  BUY,
  SELL,
}

export default class BlockTradeApi extends BaseApi {
  constructor() {
    super({});
  }

  /** 用户取消订单*/
  cancelOrder(matchOrderNo: string) {
    return this.put(`/j9bc/cancelOrder/${matchOrderNo}`);
  }

  /** 买家确认付款*/
  confirmPayment(payload: any) {
    return this.post(`/j9bc/transaction`, payload,{ignoreError: true});
  }

  /** 查询我的交易记录*/
  matchOrderPage(payload: {
    begin?: string;
    current?: number;
    end?: string;
    size?: number;
    status: number;
    type: ETradeType;
  }) {
    return this.post("/j9bc/matchOrderPage", payload);
  }

  /** 查询我的挂单记录*/
  myAdOrderPage(payload: {
    current: number;
    size: number;
    type: 0 | 1; //	广告类型(0:买 1：卖),可用值:BUY,SELL
  }) {
    return this.post("/j9bc/myAdOrderPage", payload);
  }

  /** 个人交易金额、挂单数量统计信息*/
  myTradeStats() {
    return this.post("/j9bc/myTradeStats");
  }

  /** 下架广告订单*/
  offShelfOrder(orderNo: string) {
    return this.put(`/j9bc/cancel/${orderNo}`);
  }

  /** 主页订单查询*/
  adOrderPage(payload: { current?: number; size?: number; type: ETradeType }) {
    return this.post("/j9bc/open/adOrderPage", payload);
  }

  /**用户根据订单号下单购买-出售*/
  placeAnOrder(payload: {
    orderNo: string
  }) {
    return this.post("/j9bc/placeAnOrder", payload, {ignoreError: true});
  }

  /** 发布广告订单*/
  releaseAdOrder(payload: {
    expiration: number; // 挂单时间
    price: string; // 单价
    totalAmount: string; // 总数量
    type: ETradeType;
  }) {
    return this.post("/j9bc/publish", payload);
  }

  /** 获取配置*/
  getSysConfig() {
    return this.post("/j9bc/open/getSysConfigList");
  }
}
