import React, { useEffect, useRef, useState } from "react";
import { storage } from "@/utils";
import { useNavigate } from "react-router";

export default function Dev() {
  let navigate = useNavigate();
  function jump(path: string) {
    storage.set("path", path);
    navigate(path);
  }
// return null
  return (
    <div style={{ backgroundColor: "lightcyan", color: "black" }}>
      <div style={{ color: "red" }}>
        请选择要访问的页面,仅开发环境显示此导航
      </div>
      <button onClick={() => jump("/login")}>login 登录</button>|
      <button onClick={() => jump("/swap")}>swap 交易</button>|
      <button onClick={() => jump("/")}>pledge 质押</button>|
      <button onClick={() => jump("/block_trade")}>大宗交易</button>|
      <button onClick={() => jump("/loan")}>借贷</button>|
      <button onClick={() => jump("/fiba")}>fiba</button>|
			<button onClick={() => jump("/qatar")}>Qatar</button>|
      <button onClick={() => jump("/worldcup")}>worldcup</button>|
    </div>
  );
}
