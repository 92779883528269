import React, { useEffect, useState } from "react";
import { Outlet } from "react-router";
import { observer } from "mobx-react-lite";
function Layout() {
	// const bem = useBem("layout");
	return (
		<div>
			<Outlet />
		</div>
	);
}

export default observer(Layout);
