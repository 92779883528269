import React, { ButtonHTMLAttributes, createElement, CSSProperties, ReactNode } from "react";
import BEM from "@/utils/createBEM";
import { addUnit, validateColor } from "@/utils";
import ImageLoading from "./img/loading.svg";
import "./button.styl";
import Image from "@/components/Image";
import { memberStore } from "@/stores";

const bem = BEM("btn");

export default function Button({
	disabled,
	className,
	style,
	size = "normal",
	block,
	variant = "solid",
	disabledStyle,
	color = "primary",
	icon,
	iconRight,
	iconRightStyle,
	iconStyle,
	text,
	loading,
	children,
	radius,
	textStyle,
	disabledClassName = "disabled",
	loginClick,
	...rest
}: ButtonProps) {
	const $color = validateColor(color);
	const $style = {
		"--btn-color": $color,
		"--btn-radius": typeof radius === "number" ? addUnit(radius) : radius !== undefined && radius.startsWith("circle") ? null : radius,
		...style,
		...(disabled && disabledStyle),
	} as CSSProperties;
	if (typeof size === "number") {
		$style.width = size;
	} else if (Array.isArray(size)) {
		$style.width = size[0]!;
		$style.height = size[1]!;
	}

	if (rest.onClick && loginClick) {
		if (!memberStore.isLoged) {
			rest.onClick = () => {
				window.__J9BC__.onLogin(window.__J9BC__);
			}
		}
	}

	return (
		<button
			className={bem.raw(
				bem(null, [
					typeof size === "string" ? size : "normal",
					variant,
					typeof radius === "string" && radius.startsWith("circle") && radius,
					block && "block",
					!$color && color,
					disabled && disabledClassName,
				]),
				className
			)}
			disabled={disabled || loading}
			style={$style}
			{...rest}>
			{loading ? (
				<Image src={ImageLoading} width={40} />
			) : (
				<>
					{icon && (
						<div className={bem("icon")} style={iconStyle}>
							{icon}
						</div>
					)}
					<div className={bem("text")} style={textStyle}>
						{children || text}
					</div>
					{iconRight && (
						<div className={bem("icon")} style={iconRightStyle}>
							{iconRight}
						</div>
					)}
				</>
			)}
		</button>
	);
}

export type ButtonProps = Omit<ButtonHTMLAttributes<HTMLButtonElement>, "color"> &
	Partial<{
		size: "mini" | "small" | "normal" | "medium" | "large" | number | [number | null, number | null];
		color: "primary" | "secondary" | "success" | "danger" | "warn" | "info" | ({} & string);
		variant: "solid" | "outline" | "none";
		disabledStyle: CSSProperties;
		disabledClassName: string;
		icon: ReactNode;
		iconStyle: CSSProperties;
		iconRight: ReactNode;
		iconRightStyle: CSSProperties;
		text: string;
		block: boolean;
		loading: boolean;
		textStyle: CSSProperties;
		textClassName: string;
		radius: "circle-left" | "circle-right" | "circle-all" | number | ({} & string);
		loginClick?: boolean
	}>;
