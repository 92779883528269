import Axios, {
  AxiosInstance,
  AxiosRequestConfig,
  AxiosResponse,
  CancelTokenSource,
} from "axios";
import AppConst from "@/consts/App";
import Locale from "@/consts/Locale";
import { ApiConst } from "@/consts/Network";
import appStore from "@/stores/appStore";
import memberStore from "@/stores/memberStore";
import dayjs from "dayjs";
import { toast } from "react-toastify";
var qs = require("qs");
// 统一设置axios公共配置属性
Axios.defaults.timeout = ApiConst.TIMEOUT;
Axios.defaults.withCredentials = ApiConst.WITH_CREDENTIALS;
Axios.defaults.maxRedirects = ApiConst.MAX_REDIRECTS;
// Axios.defaults.headers.post['Content-Type'] = 'application/json; charset=utf-8';
var utc = require("dayjs/plugin/utc");
var timezone = require("dayjs/plugin/timezone"); // dependent on utc plugin
dayjs.extend(utc);
dayjs.extend(timezone);

/** 接口配置项 */
export interface ApiConfig extends AxiosRequestConfig {
  /** 请求的header信息 */
  headers?: any;
  /** 是否可以被中止 */
  cancelable?: boolean;
  //是否使用jsonType
  jsonType?: boolean;
  /** 是否在请求中自动添当前环境参数(语言, frontId等) */
  withEnvParams?: boolean;
  /** 是否处理结果集, 如果报错调用系统的错误提示 */
  proccessResponse?: boolean;
  /** 特殊处理移动端/pc */
  websiteCode?: string;
  productId?: string;
  /** IM等特殊接口语言处理 */
  acceptLanguage?: string;
  /** 是否toast错误提示 */
  ignoreError?: boolean;
  /** 是否加密请求 */
  encrypt?: boolean;
}

/**
 * api基类
 */
abstract class BaseApi {
  get lang() {
    return appStore.localeUse;
  }
  readonly country = "GLO";
  /** axios 对象 */
  protected readonly axios: AxiosInstance;

  /** 接口请求配置信息 */
  private config: ApiConfig;

  /** 取消接口的凭证 */
  protected cancelSource?: CancelTokenSource;

  constructor(config: ApiConfig) {
    // if (!config.baseURL) {
    //   throw new Error('Attribute "baseURL" is required.');
    // }
    // 设置默认配置
    this.config = Object.assign(
      {
        cancelable: false,
        withEnvParams: true,
        proccessResponse: true,
        encrypt: false,
        ignoreError: false,
      },
      config
    );

    config.baseURL =
      process.env.NODE_ENV === "development" ? "" : window.__J9BC__.origin;

    // 创建axios对象
    const axios = (this.axios = Axios.create(config));

    // 请求拦截处理
    axios.interceptors.request.use(
      this.handleRequest.bind(this),
      this.handleRequestError.bind(this)
    );
    // 响应拦截处理
    axios.interceptors.response.use(
      this.handleResponse.bind(this),
      this.handleResponseError.bind(this)
    );
    axios.interceptors.request.use(function cancelIfUnmount(config: any) {
      if (window.__J9BC__.mounted) return config;
      throw new Axios.Cancel("Operation canceled by the j9bc due to inactive.");
    });
    // 构造cancel的Token
    if (this.config.cancelable) {
      this.cancelSource = Axios.CancelToken.source();
    }

    // 触发create事件
    this.onCreate();
  }

  /** 初始化事件, 子类可重写 */
  protected onCreate() {}
  /** 销毁事件, 子类可重写 */
  protected onDestory() {}

  /**
   * 发起get请求
   *
   * @param url 请求地址
   * @param config 请求的conf
   *
   * @returns 请求结果
   */
  protected get<R = any>(url: string, config?: ApiConfig): Promise<R> {
    return this.axios.get(url, config);
  }

  /**
   * 发起put请求
   *
   * @param url 请求地址
   * @param data 请求参数内容
   * @param config 请求的conf
   *
   * @returns 请求结果
   */
  protected put<R = any>(
    url: string,
    data?: any,
    config?: ApiConfig
  ): Promise<R> {
    return this.axios.put(url, data, config);
  }

  /**
   * 发起post请求
   *
   * @param url 请求地址
   * @param data 请求参数内容
   * @param config 请求的conf
   *
   * @returns 请求结果
   */
  protected post<R = any>(
    url: string,
    data?: any,
    config?: ApiConfig
  ): Promise<R> {
    return this.axios.post(url, data, config);
  }

  /** 设置是否自动添加当前环境参数 */
  protected set withEnvParams(withEnvParams: boolean) {
    this.config.withEnvParams = withEnvParams;
  }

  /**
   * 请求拦截函数
   *
   * @param conf 请求中的参数配置
   *
   * @returns 处理后的conf
   */
  private async handleRequest(conf: AxiosRequestConfig) {
    const apiConfig = conf as ApiConfig;
    // console.log(conf.data)
    // 判断是否需要自动补充参数
    if (apiConfig.withEnvParams === false) {
      return apiConfig;
    }
    apiConfig.jsonType =
      apiConfig.jsonType === undefined ? true : apiConfig.jsonType;

    if (apiConfig.jsonType) {
      apiConfig.headers["Content-Type"] ??= "application/json;charset=UTF-8";
    } else {
      apiConfig.headers["Content-Type"] ??=
        "application/x-www-form-urlencoded; charset=UTF-8";
      conf.data = qs.stringify(conf.data);
    }

    // 后台获取域名
    apiConfig.headers["X-Request-Domain"] ??= document.location.hostname;
    // 请求语言
    apiConfig.headers["Accept-Language"] ??=
      apiConfig.acceptLanguage ||
      (appStore.locale === Locale.ZH ? "cn" : appStore.locale);
    // 当前产品id
    apiConfig.headers["PRODUCT-ID"] = apiConfig.productId
      ? apiConfig.productId
      : AppConst.PRODUCT_ID;
    // x-website-code
    apiConfig.headers["x-website-code"] = apiConfig.websiteCode
      ? apiConfig.websiteCode
      : AppConst.X_WEBSITE_CODE;
    // x-website-code
    apiConfig.headers["website-code"] = apiConfig.websiteCode
      ? apiConfig.websiteCode
      : AppConst.X_WEBSITE_CODE;
    // 如果已登录则添加用户相关参数
    if (memberStore.isLoged) {
      apiConfig.headers["authorization"] = memberStore.webToken;
      apiConfig.headers["Display-Language"] =
        appStore.locale === Locale.ZH ? "cn" : appStore.locale;
      if (memberStore.userinfo.username) {
        apiConfig.headers["LOGIN-NAME"] = memberStore.userinfo.username;
      }
    }

    // 如果请求为可取消,则添加取消请求对应的token
    if (apiConfig.cancelable) {
      apiConfig.cancelToken = this.cancelSource?.token;
    }

    return apiConfig;
  }

  /**  请求阶段出错 */
  private handleRequestError(err: any) {
    console.warn("reqeust error:", err);
    return Promise.reject(err);
  }
  /**
   * 处理响应结果
   *
   * @param result 接口处理结果
   */
  private handleResponse(
    response: AxiosResponse<any>
  ): AxiosResponse<any> | Promise<AxiosResponse<any>> {
    // 判断是否需要处理结果集
    const apiConfig = response.config as ApiConfig;
    if (apiConfig.proccessResponse === false) {
      return response;
    }
    if (response?.headers?.date) {
      if (appStore.requestTime === 0) {
        // @ts-ignore
        appStore.requestTime = dayjs(response.headers.date).valueOf();
      }
    }
    const { data: result } = response;

    // 如果处理结果为200, 则表示处理成功,返回内容
    if (result.code === 0 || result.code === 200) {
      // TODO 需要根据主站的接口判断是否维护中
      // TODO 以及根据主站逻辑, 判断代码可能需要移动到其他层级位置
      //SABA 快捷投注處理
      if (result.data?.errorCode) {
        this.handleSabaError(result.data?.errorCode);
      }
      return result.data;
    }
    if (result.code === -500 && !this.config.ignoreError) {
      toast(appStore.$t("common.500"), {
        type: toast.TYPE.ERROR,
        toastId: "network_error",
      });
      return Promise.reject();
    }
    // 如果是401则需要重新登录, 提示需要登录, 清空store, 跳转到登录页面
    if (result.code === 401) {
      if (memberStore.isLoged) {
        this.handleUnauthenticated();
      }
      return Promise.reject();
    }

    // 如果响应中有msg则提示消息
    if ((result.msg || result.message) && !apiConfig.ignoreError) {
      toast.error(result.msg || result.message, { toastId: result.code });
    }
    // 返回为失败
    return Promise.reject(result);
  }

  /**
   * 网络连接异常
   * @param {object} err
   */
  private handleResponseError(err: any) {
    // 如果是因为cancel发生的错误,则不作任何提示
    if (Axios.isCancel(err)) {
      return Promise.reject();
    }
    if (err?.response?.status === 401) {
      this.handleUnauthenticated();
      return Promise.reject();
    }
    return Promise.reject(err);
  }

  /** 设置请求是否可以被中止 */
  set cancelable(cancelable: boolean) {
    this.config.cancelable = cancelable;
    // 如果可以呗取消, 则构造cancelTOken
    if (cancelable) {
      this.cancelSource = Axios.CancelToken.source();
    }
  }

  /** 销毁对象 */
  destory() {
    // 触发销毁事件
    this.onDestory();
    // 取消所有未完成并可取消的请求
    this.cancelSource?.cancel();
  }
  // 401
  handleUnauthenticated() {
    memberStore.logout();
    window.__J9BC__.onLogin(window.__J9BC__);
  }
  handleSabaError(code: number | string) {
    if (code === "B002") {
      toast("钱包余额不足", {
        type: toast.TYPE.ERROR,
        toastId: "钱包余额不足",
      });
      return;
    }
    toast(appStore.$t("common.500"), {
      type: toast.TYPE.ERROR,
      toastId: "network_error",
    });
  }
}

export default BaseApi;
