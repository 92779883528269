export interface IStorageProps {
	/** storage对象，如果没有，会使用内存进行缓存 */
	storage?: Storage;
	joiner?: string;
}

const storage: Required<IStorageProps>["storage"] = window.localStorage || Storage();

export default class StorageClass {
	private joiner: string = "_";
	private prefix: string = "";
	constructor(props?: IStorageProps) {
		Object.assign(this, props);
		//@ts-ignore
		this.prefix = ["J9BC", process.env.NODE_ENV].join(this.joiner);
	}
	private getKey(name: string) {
		return (this.prefix + this.joiner + name).toUpperCase();
	}
	get(name: string, defaultValue: any = null) {
		const data = storage.getItem(this.getKey(name));
		if (!data) return defaultValue;
		return JSON.parse(data).data;
	}
	set(name: string, data: any) {
		name = this.getKey(name);
		return storage.setItem(
			name,
			JSON.stringify({
				type: typeof data,
				data,
			})
		);
	}
	remove(name: string) {
		return storage.removeItem(this.getKey(name));
	}
	clear() {
		return storage.clear();
	}
}

function Storage() {
	let store: Record<string, any> = {};
	return {
		getItem: function (key: string) {
			return store[key] || null;
		},
		setItem: function (key: string, value: string) {
			store[key] = value.toString();
		},
		removeItem: function (key: string) {
			delete store[key];
		},
		clear: function () {
			store = {};
		},
	} as Storage;
}
