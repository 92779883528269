import J9BCapi from "./J9BCapi";
import LOANapi from "./loan";
import Texasapi from './Texasapi'
import BlockTradeApi from './BlockTradeApi'
import CmsApi from './CmsApi'
import WorldcupApi from "./WorldcupApi";
export const loanApi = new LOANapi();
export const j9bcApi = new J9BCapi();
export const texasApi = new Texasapi();
export const blockTradeApi = new BlockTradeApi();
export const cmsApi = new CmsApi();
export const worldcupApi = new WorldcupApi();
