import { makeObservable, observable, action } from  "mobx";
// import modalControlStore, { ModalNames } from "stores/modalControlStore";
class unread {

  @observable unreadNum:any={}
  @observable loantype:any={}
	constructor() {
		makeObservable(this);
	}
  @action
	setUnreadNum(param:any) {
	 	this.unreadNum=param
	}
  @action
	setloantype(param:any) {
	 	this.loantype=param
	}
}

export default new unread();