import { makeAutoObservable } from "mobx";
import Locale, { DEFAULT_LOCALE } from "@/consts/Locale";
import { createIntl, IntlShape } from "react-intl";
import flatObject from "@utils/flatObject";
import { storage } from "@/utils/storage";
export const TOKEN_CACHE_KEY = "language";
export const INIT_CACHE_KEY = "init";
type Args = Parameters<IntlShape["formatMessage"]>;

/** 系统store */
class AppStore {
  /** 当前语言 */
  private _locale: Locale;
  private _intl?: IntlShape;
  private _intlLoading: boolean = true;
  private _appInit = {} as AppInit;
  private _productInfo = {} as ProductInfoType;
  private _requestTime: number = 0;
  private _getPublicKey: string = "";
  private _googleRecaptchaToken: string = "";
  private _usableWebp: boolean = true;

  private _website_code = "";
  private _product_id = "";
  constructor() {
    // 设置当前对象为observeable
    makeAutoObservable(this);
    this._locale =
      (window.__J9BC__.lang as any) ||
      storage.get(TOKEN_CACHE_KEY) ||
      DEFAULT_LOCALE;
    this.appInit = storage.get(INIT_CACHE_KEY) || {};
    this.detectUsableWebp();
  }
  private async detectUsableWebp() {
    const key = "WEBP_CAHCE_KEY";
    this._usableWebp = storage.get(key);
    if (this._usableWebp === undefined) {
      this._usableWebp = document
        .createElement("canvas")
        .toDataURL("image/webp", 0.5)
        .startsWith("data:image/webp");
      storage.set(key, this._usableWebp);
    }
  }
  async createIntl() {
    try {
      this._intlLoading = true;
      // 加载对应的语言包
      const messages = await import(`../assets/locales/${this._locale}`);
      document.documentElement.lang = this.locale;
      this._intl = createIntl({
        locale: this._locale,
        messages: flatObject(messages.default),
      });
    } catch (e) {
      console.error(e);
    } finally {
      this._intlLoading = false;
    }
  }
  /** 设置当前语言 */
  set locale(locale: Locale) {
    if (locale === this._locale) {
      return;
    }
    this._locale = locale;
    this.createIntl();
    storage.set(TOKEN_CACHE_KEY, locale);
  }

  /** 获取当前语言 */
  get locale() {
    return this._locale;
  }

  /** 转换ZH */
  get localeUse() {
    return this._locale === "zh" ? "cn" : this._locale;
  }

  /** 设置初始化参数 */
  set appInit(data: AppInit) {
    // data.isHX3Domain=true
    this._appInit = data;
    storage.set(INIT_CACHE_KEY, data);
  }

  /** 获取初始化参数 */
  get appInit() {
    return this._appInit;
  }

  set productInfo(data: ProductInfoType) {
    this._productInfo = data;
    // 1.若是localstorage无存语言(第一次访问) 使用返回lang当app语言
    // 2.若是有存则是使用所存语言(包含更改)
    const language = storage.get(TOKEN_CACHE_KEY);
    if (language) {
      this.locale = language === ("zh" || "cn") ? Locale.ZH : Locale.EN;
      return;
    }
    storage.set(TOKEN_CACHE_KEY, data.lang);
    this.locale = data.lang === ("zh" || "cn") ? Locale.ZH : Locale.EN;
  }
  get productInfo() {
    return this._productInfo;
  }

  get intl() {
    return this._intl as IntlShape;
  }
  get intlLoading() {
    return this._intlLoading;
  }
  $t(descriptor?: Args[0] | string, values?: Args[1], opts?: Args[2]) {
    if (!this.intl) return;
    if (!descriptor) return "";
    if (typeof descriptor === "string") {
      descriptor = { id: descriptor };
    }
    return this.intl.formatMessage(descriptor, values, opts) as string;
  }
  set requestTime(date: number) {
    this._requestTime = date;
  }
  get requestTime() {
    return this._requestTime;
  }
  set googleRecaptchaToken(date: string) {
    this._googleRecaptchaToken = date;
  }
  get googleRecaptchaToken() {
    return this._googleRecaptchaToken;
  }
  get usableWebp() {
    return this._usableWebp;
  }
  set website_code(code: string) {
    this._website_code = code;
  }
  get website_code() {
    return this._website_code;
  }
  set product_id(id: string) {
    this._product_id = id;
  }
  get product_id() {
    return this._product_id;
  }
}

export default new AppStore();

// 后续新增手动扩展
interface AppInit {
	gameCrashUrlH5: string;
	gameCrashUrlPc: string;
	cryptoKey: string;
	isHX3Domain?: boolean;
	isHX2Domain?: boolean;
	isGoogleLoginDomainStr?: boolean;
	isSmsVerify?: boolean;
	capathaType: number | string;
	limitUrl?: Array<string>;
	isRobotDomainStr?: boolean;
}

interface ProductInfoType {
	country: string;
	countryShort: string;
	lang: string;
	langCountryRelation: object;
	langList: Array<string>;
	langProductRelation: object;
	productId: string;
	realCountryCode: string;
}
