/**
 * 系统常量
 */
const id = process.env.REACT_APP_PRODUCT_ID;
export const AppConst = {
	/** 产品ID */
	PRODUCT_ID: "H86",
	/** website code */
	X_WEBSITE_CODE: "H86_PC",
	/** 服务端进行厅方跳转判断 */
	X_WEBSITE_CODE_H5: "H86_H5",
	/** storage的前缀 */
	PREFIX: "HX1.CHCHE",
	/** getCacheData的默认缓存时间 */
	DURATION_FOR_CACHE_DATA: 10000,
	/** 缓存版本号, 修改后用户端会清空所有storage中的缓存内容 */
	VERSION: 1.0,
	/** 国家参数 */
	CUR_COUNTRY_PARAM: id === "H86" ? "CN" : id === "H91" ? "IN" : id === "H84" ? "VN" : "",
	/** 默认区号 */
	CURRENT_CODE: id === "H86" ? "+86" : id === "H91" ? "+91" : id === "H84" ? "+84" : "",
};

export default AppConst;
