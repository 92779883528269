export const enum StorageNames {
	/** 请求Authorization*/
	Authorization = "authorization",
	/** iframe带的令牌*/
	URLtoken = "entry_token",
	/** 商户id */
	Search = "querysting",
	/** 商户参数 */
	MerchantParams = "merchantParams",
	/** 用户信息*/
	UserInfo = "userinfo",
}
