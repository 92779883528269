import AppConst from "@/consts/App";
import { appStore, memberStore } from "@/stores";
import BaseApi, { ApiConfig } from "./BaseApi";

export default class J9BCapi extends BaseApi {
  constructor() {
    super({});
  }
  // 获取赛事数据接口
  getEvents({loginName}:{loginName?:string}) {
    const config: ApiConfig = {
      websiteCode: appStore.website_code
        ? appStore.website_code
        : AppConst.X_WEBSITE_CODE,
      productId: appStore.product_id
        ? appStore.product_id
        : AppConst.PRODUCT_ID,

      jsonType: false,
    };
    const params:{[key:string]:any} = {
      language: "cs",
      platType: "SHABA",
      productId: appStore.product_id
        ? appStore.product_id
        : AppConst.PRODUCT_ID,
      // loginName:"j9gene01",
      sid: "123123",
    };
    if (loginName) params.loginName = loginName;
    return this.post(
      "/api/pd/game-front/oauth/game/quickBetting/GetEvens",
      params,
      config
    );
  }
  // 获取盘口赔率数据
  getSingleTicket(params: { key: string; marketId: string | number }) {
    const config: ApiConfig = {
      websiteCode: appStore.website_code,
      productId: appStore.product_id,

      jsonType: false,
    };
    return this.post(
      "/api/pd/game-front/game/quickBetting/getSingleTicket",
      {
        language: "cs",
        loginName: memberStore.userinfo.username,
        platType: "SHABA",
        productId: appStore.product_id,
        ...params,
      },
      config
    );
  }
  // 下注接口 接口返回
  /**
key	投注类型选项
loginName	登录名	
marketId	盘口ID
point	球头
price	赔率	
productId	产品ID
stake	下注金额
	 */
  placeBet(params: {
    key: string;
    marketId: string | number;
    point: string;
    price: number;
    stake: betAmountType;
  }) {
    const config: ApiConfig = {
      websiteCode: appStore.website_code,
      productId: appStore.product_id,

      jsonType: false,
    };
    return this.post(
      "/api/pd/game-front/game/quickBetting/placeBet",
      {
        language: "cs",
        loginName: memberStore.userinfo.username,
        platType: "SHABA",
        productId: appStore.product_id,
        ...params,
      },
      config
    );
  }
}
