/**
 * ### 语言枚举
 *
 * * ZH: zh(中文)
 * * EN: en(英文)
 * * VI: vi(越南文)
 * * TH: th(泰语)
 * * JP: jp(日文)
 */
 export enum Locale {
  /** 
   * ZH:中文 
   * 
   * 因react-intl下非ISO-639的值报错, 所以还是使用的zh表示中文
   * 接口处修改为了cn
   */
  ZH = 'zh',
  /** en:英文 */
  EN = 'en',
  // TODO 添加其他语言支持
  // /** vi:越南文 */
  VI = 'vi',
  // /** th:泰语 */
  // TH = 'th',
  // /** jp:日文 */
  // JP = 'jp',
}
/** 默认语言(zh:简体中文) */
export const DEFAULT_LOCALE: Locale = Locale.ZH;

/** 系统可选语言列表 */
export const LOCALE_LIST: Locale[] = [
  Locale.ZH,
  Locale.EN,
  Locale.VI,
  // Locale.TH,
  // Locale.JP,
];

export default Locale;
