import { memberStore } from "@/stores";
import BaseApi from "./BaseApi";

export default class J9BCapi extends BaseApi {
	constructor() {
		super({});
	}
	/** 登录,用第三方传入的ticket获取token */
	getAuthToken({ token }: { token: string }) {
		return this.get("/api/lobby/join/" + token + "");
	}
	/** 获取用户数据 */
	getUserData() {
		return this.get("/api/customer/data");
	}
	/** 获取用户信息 */
	getUserInfo() {
		return this.get("/api/customer/info");
	}
	/** 获取用户钱包 */
	getUserWallet() {
		return this.get("/api/customer/wallet");
	}
	/** 奖池总览 */
	jackpotOverview() {
		return this.get("/api/jackpot/open/overview");
	}
	/** 每日分红排行榜 */
	jackpotRank() {
		return this.get("/api/jackpot/open/rank");
	}
	/** 质押分红信息 */
	jackpotMine() {
		return this.get("/api/jackpot/mine");
	}
	/** 领取分红 */
	jackpotDividend() {
		return this.get("/api/jackpot/dividend");
	}
	/** 质押 */
	jackpotPledge(payload: { amount: number }) {
		return this.put("/api/jackpot/pledge", payload, { jsonType: false });
	}
	/** 解除质押 */
	jackpotRedeemed() {
		return this.put("/api/jackpot/redeemed", {}, { jsonType: false });
	}
	/** 全站分红记录 */
	jackpotAllDividend(params: any) {
		return this.post("/api/jackpot/records/open/all-dividend", params);
	}
	/** 个人分红记录 */
	jackpotMineDividend(params: any) {
		return this.post("/api/jackpot/records/mine-dividend", params);
	}
	/** 质押/解除记录 */
	jackpotPledgeRecord(data: any) {
		return this.post("/api/jackpot/records/pledge", data);
	}
	/** 最近分红数据 */
	jackpotRecentDividend() {
		return this.get("/api/jackpot/records/open/recent-dividend", { params: { day: 7 } });
	}
	/** 最近分红数据 */
	jackpotRecordsToken() {
		return this.post("/api/jackpot/records/token");
	}

	/********** swap ***********/
	/** 根据to计算from额度 */
	swapCalcFrom(payload: { token: string; tradeCode: string; value: number }) {
		return this.post("/api/swap/open/calc/from", payload);
	}
	/** 根据from计算to额度 */
	swapCalcTo(payload: { token: string; tradeCode: string; value: number }) {
		return this.post("/api/swap/open/calc/to", payload);
	}
	/** 获取池子信息 */
	swapInfo(payload: { token: string; tradeCode: string; value: number }) {
		return this.post("/api/swap/info", payload);
	}
	/** Swap K线 */
	KLine(payload: { basicToken: string; type: "DAY" | "MONTH" | "WEEK" | "YEAR"; code: string }) {
		return this.post("/api/swap/open/k-line", payload);
	}
	/** 交易信息 */
	tradeInfo({ tradeCode, token }: { tradeCode: string; token: Token }) {
		return this.get(`/api/swap/open/trade-pair/info/${tradeCode}/${token}`);
	}
	/** 根据code获取交易对 */
	queryTradeByCode({ tradeCode }: { tradeCode: string }) {
		return this.get(`/api/swap/open/trade-pair/query/${tradeCode}`);
	}
	/** 获取所有交易对 */
	queryTradeList() {
		return this.get(`/api/swap/open/trade-pair/list`);
	}
	/** 交易记录分页查询 */
	swapRecord(payload: { page: number; pageSize?: number }) {
		return this.post(`/api/swap/record/page`, payload);
	}
	/** 进行交易 */
	swapTransaction(payload: {
		/*支付token,可用值:AXS,BNB,DOGE,J9BC,LTC,LUNA,SHIB,USDT	 */
		fromToken?: string;
		/*支付token的额度		false	 */
		fromValue?: number;
		/*滑点容差		false	 */
		slippageTolerance?: number;
		/*收款token的额度		false	 */
		toValue?: number;
		/*交易对编号		false	 */
		tradeCode?: string;
	}) {
		return this.post(`/api/swap/trading-pair/transaction`, payload);
	}

	/** 额度查询 */
	swapBalance(payload: { tradeCode: string }) {
		return this.get(`/api/swap/${payload.tradeCode}/balance`);
	}
	/** 近5日回购数据 */
	paybackHistory() {
		return this.get(`/api/payback/open/last-few`);
	}
	/** 回购总览 */
	paybackOverview() {
		return this.get(`/api/payback/open/overview`);
	}
	/** 回购记录 */
	paybackRecords(payload: { begin: string; current: number; end: string; size: number }) {
		return this.post(`/api/payback/open/records`, payload);
	}
	/** 最近交易记录 */
	swapRecords(payload: { begin: string; current: number; end: string; size: number }) {
		return this.post(`/api/swap/open/records`, payload);
	}
	/** 质押类型 */
	pledgeTypes() {
		return this.get("/api/pd/missions-center/open/j9bc/pledge-type");
	}
	/** =========================FOMO==========================**/

	/** 未登录fomo详情*/
	fomoDetails() {
		return this.get('/j9bc/open/currentFomo')
	}

	/** 登录fomo详情*/
	loginFomoDetails() {
		return this.get('/j9bc/fomo/currentFomo')
	}

	/** 购买FOMO票*/
	buyTicket(payload: {ticket: number, rate?: number}) {
		return this.post('/j9bc/fomo/buyTicket',payload, {jsonType: true})
	}

	/** 获取倒计时 */
	getOverTime() {
		return this.get("/j9bc/open/getOverTime");
	}
	/** fomo 奖池信息 */
	fomoJackPotRecord(payload: any) {
		return this.get("/j9bc/open/fomoJackPotRecord", {params:payload});
	}
	/** fomo 新全站分红记录 */
	recordsAllDividendNew(payload: any) {
		return this.get("/api/jackpot/records/open/all-dividend-new", {params:payload});
	}
	/** fomo 新个人分红记录 */
	recordsMineDividendNew(payload: any) {
		return this.get("/api/jackpot/records/mine-dividend-new", {params:payload});
	}
	/** fomo 我的奖券 */
	myFomoRecord(payload: any) {
		return this.get("/j9bc/fomo/fomoRecord", {params:payload});
	}
}

type Token = "AXS" | "BNB" | "DOGE" | "J9BC" | "LTC" | "LUNA" | "SHIB" | "USDT";
