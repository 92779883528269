import React, { PropsWithChildren, useEffect, useLayoutEffect, useState } from "react";
import { RawIntlProvider } from "react-intl";
import appStore from "@/stores/appStore";
import Toastify from "@component/Toastify/Toastify";
import { ModalConstroller } from "@pages/Modals";
import UiState from "@/stores/UiState";
import CssProperties from "@/consts/CssProperties";

export default function AppInitial({ children }: PropsWithChildren<{}>) {
	const [loading, setLoading] = useState(true);
	useEffect(() => {
		const rootEl = document.getElementsByClassName('j9bc-root')[0]
		const headerRect = rootEl?.getBoundingClientRect();
		UiState.setCssProperty(
			{
				prop: CssProperties.headerHeight,
				val: String(headerRect?.top || 0)
			}
		)
		appStore.createIntl().then(() => setLoading(false));
	}, []);

	if (loading) return null;
	return (
		<RawIntlProvider value={appStore.intl}>
			<Toastify />
			<ModalConstroller />
			{children}
		</RawIntlProvider>
	);
}
