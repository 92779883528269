import React, { forwardRef, RefObject, useState, useImperativeHandle, cloneElement } from "react";
import Modals from "./Modals";
import ModalName from "./ModalNames";
import { PureComponent } from "react";
export const controller = {} as {
	open(modalName: ModalName): void;
	close(modalName: ModalName): void;
};

export default class ModalConstroller extends PureComponent {
	state!: {
		modalsName: ModalName[];
	};
	constructor(props: any) {
		super(props);
		this.state = {
			modalsName: [],
		};
	}

	get modals() {
		const modals = [];
		for (let i = 0, len = this.state.modalsName.length; i < len; i++) {
			const name = this.state.modalsName[i];
			const modal = Modals.find((m) => m.modalName === name);
			if (modal) {
				modals.push(modal.component);
			}
		}
		return modals;
	}

	open = (name: ModalName) => {
		if (this.state.modalsName.includes(name)) return;
		this.state.modalsName.push(name);
		this.forceUpdate();
	};
	close = (name: ModalName) => {
		const index = this.state.modalsName.findIndex((modalName) => modalName === name);
		if (!~index) return;
		this.state.modalsName.splice(index, 1);
		this.forceUpdate();
	};

	componentDidMount() {
		controller.open = this.open;
		controller.close = this.close;
	}
  
  componentWillUpdate(){
    if(this.modals.length === 0) {
      document.body.classList.remove("j9bc-modal__after-open")
    }
  }

	render() {
		return <div>{this.modals.map((modal, index) => cloneElement(modal, { key: index }))}</div>;
	}
}
