import React from "react";
import ReactDOM, { Root } from "react-dom/client";
import App from "./pages/App";
import Locale from "@/consts/Locale";
import * as stores from "@/stores";
import "swiper/css/bundle";
import "./index.css";
import "./assets/styles/Montserrat.less";
import "rc-pagination/assets/index.css";
import "rc-tabs/assets/index.css";
import "rc-tooltip/assets/bootstrap.css";
import "tailwindcss/tailwind.css";
// import "@assets/styles/rc-drawer.less";
// import "rc-menu/assets/index.css";

const setting = window.__J9BC__;
// 初始化登录事件
setting.authed = stores.memberStore.isLoged;
setting.login = async function login(ticket) {
  const res = await stores.memberStore.login(ticket);
  setting.authed = true;
  return res;
};
setting.logout = function login() {
  stores.memberStore.logout();
  setting.authed = false;
};
let lastRoot: Root;

window.addEventListener("__J9BCInit__", (event: CustomEventInit) => {
  //初始化时如果携带token,那么直接登录
  if (setting.ticket) {
    setting.login(window.__J9BC__.ticket);
  }

  Object.assign(setting, event.detail as Window["__J9BC__"]);
  if (!setting.el) {
    throw Error(
      "参数 el 不能为空, 请传入用于显示组件的容器选择器值, 如: #qbContainer"
    );
  }
  let el =
    setting.el instanceof HTMLElement
      ? setting.el
      : document.querySelector(setting.el);
  if (!el || el.nodeType !== 1) {
    throw new Error("渲染容器未找x到,或格式不正确, 建议使用div");
  }

  lastRoot && lastRoot.unmount();
  let root = (lastRoot = ReactDOM.createRoot(el as HTMLElement));
  setting.unmount = () => {
    setting.mounted = false;
    root.unmount();
  };
  stores.appStore.locale = (setting.lang as any) || stores.appStore.locale;
  if (setting.store?.website_code)
    stores.appStore.website_code = setting.store.website_code;
  if (setting.store?.productId)
    stores.appStore.product_id = setting.store.productId;
  if (setting.store?.isLogin) {
    stores.memberStore.webToken = setting.store.webToken;
    stores.memberStore.userinfo.username = setting.store.loginName;
  }

  setting.setLang = (lang: Locale) => {
    stores.appStore.locale = lang;
    setting.lang = lang;
  };
  console.log({ event: event.detail });
  root.render(<App />);
  setting.onReady(el as any);
});
