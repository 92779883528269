import { floor, isFinite } from "lodash-es";

export type localeNumberProps = {
	/** 最大小数位 */
	decimal?: number;
	/** 是否填充小数位 */
	padding?: boolean;
	/** 前缀 */
	prefix?: string;
	/** 固定整数位长度 */
	integer?: number;
	/** 空值时返回的数据 默认0 */
	fallback?: any;
	options?: Intl.NumberFormatOptions;
};
export function localeNumber(
	n: string | number,
	{ decimal = 2, padding = true, prefix = "", integer, fallback = 0, options }: localeNumberProps = {}
) {
	if (isNaN(n as any)) n = fallback;
	const $decimal = decimal < 0 ? undefined : decimal;

	return (
		prefix +
		(n || 0).toLocaleString(undefined, {
			minimumFractionDigits: padding ? $decimal : undefined,
			minimumIntegerDigits: integer,
			maximumFractionDigits: $decimal,
		})
	);
}
export default localeNumber;

export const localeNumberDollar: typeof localeNumber = function (n, options) {
	return localeNumber(n, {
		prefix: "$",
		...options,
	});
};
