import React, { useEffect } from "react";
import ReactDOM from "react-dom";
import { ToastContainer, Zoom as Animate, toast } from "react-toastify";

import "./toastify.styl";

export default function Taistify() {
	// 处理Toast条数过多问题
	useEffect(() => {
		const handleToast = () => {
			toast.clearWaitingQueue();
		}
		const toastEl = document.getElementById("j9bc-toast-container")
		toastEl?.addEventListener("DOMSubtreeModified",handleToast)
		return () => {
			toastEl?.removeEventListener("DOMSubtreeModified", handleToast)
		}
	}, [])

	return ReactDOM.createPortal(
		<div className="j9bc-toast">
			<ToastContainer
				className={(names) => {
					return (
						names?.defaultClassName
							?.split(" ")
							.map((i) => "j9bc-"+i)
							.join(" ") || ""
					);
				}}
				icon={false}
				containerId="j9bc-toast-container"
				position={toast.POSITION.TOP_CENTER}
				autoClose={2000}
				limit={1}
				pauseOnHover={false}
				pauseOnFocusLoss={false}
				closeButton={false}
				hideProgressBar
				transition={Animate}
				theme="colored"
			/>
		</div>,
		document.body
	);
}
