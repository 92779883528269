import React, { CSSProperties, lazy, PropsWithChildren, Suspense, useLayoutEffect, useEffect } from "react";
import AppInitial from "@/initial";
import { MemoryRouter, Routes, Route, Navigate, useNavigate } from "react-router";
import Layout from "./Layout";
import Entry from "@/pages/Dev/Entry";
import { observer } from "mobx-react-lite";
import { storage } from "@/utils";

const Home = lazy(() => import("./Home"));
const Swap = lazy(() => import("./Swap"));
const Login = lazy(() => import("./Dev/Login"));
// const BlockTrade = lazy(() => import("./BlockTrade"));
const Fiba = lazy(() => import("./Fiba"));
const Qatar = lazy(() => import("./Qatar"))
const WorldCup = lazy(()=>import("./WorldCup"))
const Loan = lazy(() => import("./Loan/index"));
const isDev = process.env.NODE_ENV === "development";

function App() {
	useEffect(() => {
    window.process = {
      ...window.process,
    };
  }, []);

	return (
    <div
      className="j9bc-root"
      id="j9bc-root"
      style={
        { "--j9bc-background": window.__J9BC__.background } as CSSProperties
      }
    >
      <Suspense fallback={<div></div>}>
        <AppInitial>
          <MemoryRouter
            initialEntries={[
              // isDev ? storage.get("path") || "/" :
               window.__J9BC__.route,
            ]}
          >
            <ExportRouter />
            {process.env.NODE_ENV === "development" && <Entry />}
            <Routes>
              <Route path="/" element={<Layout />}>
                <Route index element={<Home />} />
                <Route path="swap" element={<Swap />} />
                <Route path="loan" element={<Loan />} />
                <Route path="fiba" element={<Fiba />} />
                <Route path="Qatar" element={<Qatar />} />
                <Route path="worldcup" element={<WorldCup />} />
                {/* <Route path="block_trade" element={<BlockTrade />} /> */}
              </Route>
              {isDev && <Route path="/login" element={<Login />} />}
              <Route path="*" element={<Navigate to="/" replace />} />
            </Routes>
          </MemoryRouter>
        </AppInitial>
      </Suspense>
    </div>
  );
}

function ExportRouter() {	
	const navigate = useNavigate();
	useLayoutEffect(() => {
		window.__J9BC__.setRoute = function (to, options) {			
			navigate(to, options);
			window.__J9BC__.route = to as any;
		};
	}, []);
	return null;
}

export default observer(App);
