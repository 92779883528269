import React, { MouseEventHandler, ReactElement, SVGProps, useEffect, useState } from "react";
import ReactModal from "react-modal";
import ReactDOM from "react-dom/client";
import Props, { IconCatType, SkinType, Reason } from "./Props";
import createBEM from "@/utils/createBEM";
import appStore from "@/stores/appStore";

import Button from "@/components/Button";
import Image from "@/components/Image";

import IconOkay from "./img/cat-success@2x.png";
import IconNotOKay from "./img/cat-error@2x.png";
import IconInfo from "./img/cat-warning@2x.png";

import "./index.less";
import { noop } from "lodash-es";

var sheet = document.createElement("style");
sheet.type = "text/css";
sheet.textContent = `:root{--j9bc-body-scroller-bar-width:${getScrollbarWidth() + "px"}}`;
document.head.appendChild(sheet);

const IconGroup = ["", IconOkay, IconNotOKay, IconInfo];

export type ModalProps = Props;
export function Modal({
	skin = SkinType.BLUE,
	Header,
	Body,
	Footer,
	title,
	CloseButton,
	CloseButtonProps,
	confirmButtonText = appStore.$t("common.confirm"),
	confirmButtonProps,
	cancelButtonProps,
	isCancelShow = true,
	cancelButtonText = appStore.$t("common.decline"),
	width,
	height,
	position = "center",
	onCancel = noop,
	onConfirm = noop,
	fullscreen,
	children,
	type = "confirm",
	scrollbody = "self",
	style,
	titleAlign,
	isOpen: _isOpen,
	icon,
	align,
	autoClose = true,
	onRequestClose: onClose,
	...props
}: Props) {
	const [isOpen, setisOpen] = useState(false);
	useEffect(() => {
		// 如果初始化为true,body类名将不会被移除,故使用异步方式
		Promise.resolve().then(() => {
			if (_isOpen) {
				setisOpen(true);
			} else {
				close(Reason.None);
			}
		});
	}, [_isOpen]);

	const onRequestClose: Props["onRequestClose"] = async (e) => {
		if (e instanceof KeyboardEvent) {
			close(Reason.Esc);
		} else {
			close(Reason.Overlay);
		}
	};

	const close = (reason: any) => {
		if (autoClose) {
			setisOpen(false);
		}
		onCancel(
			autoClose
				? noop
				: function close() {
						setisOpen(false);
				  },
			reason
		);
	};

	const confirm: MouseEventHandler<HTMLButtonElement> = (e) => {
		if (autoClose) {
			setisOpen(false);
		}
		onConfirm?.(
			autoClose
				? noop
				: function close() {
						setisOpen(false);
				  }
		);
	};

	const bem = createBEM(skin);

	return (
		<ReactModal
			isOpen={isOpen}
			onRequestClose={onRequestClose}
			overlayClassName={bem("overlay", position)}
			portalClassName={bem("portal")}
			className={bem("body")}
			bodyOpenClassName={null}
			htmlOpenClassName={"j9bc-after-open"}
      appElement={document.body}
			preventScroll
			style={{
				...style,
				content: {
					width,
					height,
					...style?.content,
				},
			}}
			{...props}>
			<Slot slot={CloseButton}>
				<button className={bem("close-button")} onClick={() => close(Reason.Close)}>
					<ButtonClose />
				</button>
			</Slot>
			{!!icon && (
				<div className={bem("icon")}>
					<Image src={IconGroup[icon]} className={bem("img")} width={163} />
				</div>
			)}

			<Slot slot={Header}>
				<div className={bem("header")} style={{ textAlign: titleAlign }}>
					{title}
				</div>
			</Slot>

			<Slot slot={Body}>
				<div className={bem("main")} style={{ textAlign: align }}>
					{children}
				</div>
			</Slot>

			{type !== "message" && (
				<Slot slot={Footer}>
					<div className={bem("footer")}>
						{isCancelShow && (
							<Button variant="outline" size={"large"} {...cancelButtonProps} onClick={close}>
								{cancelButtonProps?.text || cancelButtonText}
							</Button>
						)}
						<Button className={bem("button", "confirm")} size={"large"} {...confirmButtonProps} onClick={confirm}>
							{confirmButtonProps?.text || confirmButtonText}
						</Button>
					</div>
				</Slot>
			)}
		</ReactModal>
	);
}

type QuicklyProps = Omit<Props, "isOpen">;
const container = document.createDocumentFragment();
function open(config: QuicklyProps) {
	let modalRoot = ReactDOM.createRoot(container);
	config.align ||= "center";
	config.width ||= 400;
	config.title ??= false;
	modalRoot.render(<Modal isOpen {...config} key={Date.now() + Math.random()} />);
}

Modal.alert = function (config: QuicklyProps) {
	return open({
		isCancelShow: false,
		...config,
	});
};

Modal.success = function (config: QuicklyProps) {
	return open({
		icon: IconCatType.SUCCESS,
		...config,
	});
};

Modal.error = function (config: QuicklyProps) {
	return open({
		icon: IconCatType.ERROR,
		...config,
	});
};

Modal.info = function (config: QuicklyProps) {
	return open({
		icon: IconCatType.INFO,
		...config,
	});
};

Modal.confirm = function (config: QuicklyProps) {
	return open(config);
};

Modal.prompt = function (config: QuicklyProps) {
	return open({
		type: "message",
		...config,
	});
};

function Slot({ slot, children }: { slot: ReactElement | false | undefined; children: ReactElement }): ReactElement {
	if (slot === false) return null as any;
	return slot || children;
}

function ButtonClose(props: SVGProps<any>) {
	return (
		<svg version="1.1" viewBox="0 0 512 512" width="64" {...props}>
			<path d="M443.6,387.1L312.4,255.4l131.5-130c5.4-5.4,5.4-14.2,0-19.6l-37.4-37.6c-2.6-2.6-6.1-4-9.8-4c-3.7,0-7.2,1.5-9.8,4  L256,197.8L124.9,68.3c-2.6-2.6-6.1-4-9.8-4c-3.7,0-7.2,1.5-9.8,4L68,105.9c-5.4,5.4-5.4,14.2,0,19.6l131.5,130L68.4,387.1  c-2.6,2.6-4.1,6.1-4.1,9.8c0,3.7,1.4,7.2,4.1,9.8l37.4,37.6c2.7,2.7,6.2,4.1,9.8,4.1c3.5,0,7.1-1.3,9.8-4.1L256,313.1l130.7,131.1  c2.7,2.7,6.2,4.1,9.8,4.1c3.5,0,7.1-1.3,9.8-4.1l37.4-37.6c2.6-2.6,4.1-6.1,4.1-9.8C447.7,393.2,446.2,389.7,443.6,387.1z" />
		</svg>
	);
}

function getScrollbarWidth() {
	const outer = document.createElement("div");
	outer.style.visibility = "hidden";
	outer.style.overflow = "scroll";
	document.body.appendChild(outer);
	const inner = document.createElement("div");
	outer.appendChild(inner);
	const scrollbarWidth = outer.offsetWidth - inner.offsetWidth;
	outer.parentNode!.removeChild(outer);
	return scrollbarWidth;
}
