import { action, makeAutoObservable, observable } from "mobx";

export enum SCREEN_SIZE {
	SMALL = "SMALL",
	MEDIUM = "MEDIUM",
	LARGE = "LARGE",
}

class UI {
	windowDimensions = {
		width: window.innerWidth || document.body.clientWidth,
		height: window.innerHeight || document.body.clientHeight,
	};
	headerHeight = 0;
	scrollY = 0;

	constructor() {
		// .struct 确保对象只有以 deepEqual 的方式更新时，才会触发 observer
		makeAutoObservable(this, { windowDimensions: observable.struct });
		window.addEventListener("onresize", (ev) => {
			this.getWindowDimensions(ev as any);
		});
	}
	/**
	 * getWindowDimensions 获取设置浏览器参数
	 * @param ev
	 */
	@action
	getWindowDimensions(ev: UIEvent) {
		this.windowDimensions = {
			width: window.innerWidth || document.body.clientWidth,
			height: window.innerHeight || document.body.clientHeight,
		};
	}

	/**
	 * screenSize 获取浏览器目前尺寸
	 */
	get screenSize() {
		const { width } = this.windowDimensions;
		if (width <= 960) {
			return SCREEN_SIZE.SMALL;
		}
		if (width <= 1300) {
			return SCREEN_SIZE.MEDIUM;
		}
		return SCREEN_SIZE.LARGE;
	}

	get isH5() {
		return this.screenSize === SCREEN_SIZE.SMALL;
	}

	/**
	 * screenWidth 获取浏览器目前尺寸
	 */
	get screenWidth() {
		const { width } = this.windowDimensions;
		return width;
	}

	/** 设置css变量*/
	setCssProperty({prop,val}:{prop: string, val: string}) {
		document.documentElement.style.setProperty(prop, val)
	}

	/** 是否滚动*/
	get isScrolled() {
		return this.scrollY > 0;
	}
}

export default new UI();
