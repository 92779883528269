import { ButtonHTMLAttributes, CSSProperties, ReactElement, ReactNode } from "react";
import ReactModal, { Props } from "react-modal";
import { ButtonProps } from "@/components/Button";
export enum Reason {
	Overlay = "overlay",
	Esc = "esc",
	Router = "router",
	Close = "close",
	Cancel = "cancel",
	None = "none",
}
/** 弹窗猫咪类型*/
export enum IconCatType {
	NONE,
	SUCCESS,
	ERROR,
	INFO,
}

export enum SkinType {
	BLUE = "modal",
	ORANGE = "modal-orange",
}

type ModalProps = Props &
	Partial<{
		/** 皮肤名称，自定义整个弹窗ui */
		skin: SkinType | string;
		/** 自定义头部 */
		Header: ReactElement | false;
		/** 自定义主体 */
		Body: ReactElement | false;
		/** 自定义底部 */
		Footer: ReactElement | false;
		/** 标题 */
		title: ReactElement | string | false;
		titleAlign: CSSProperties["textAlign"];
		align: CSSProperties["textAlign"];
		/** html字符串 */
		html: string;
		/** 显示的猫咪图片,0 没有图片，1成功图片， 2 失败，3 普通提示*/
		icon: IconCatType;
		/** 是否显示footer左边的cancel按钮*/
		isCancelShow?: boolean;
		/** 取消按钮文字 */
		cancelButtonText: string;
		/** 取消按钮属性 */
		cancelButtonProps: ButtonProps & { text?: string | ReactElement };
		/** 确认按钮文字 */
		confirmButtonText: string;
		/** 确认按钮文字 */
		confirmButtonProps: ButtonProps & { text?: string | ReactElement };
		/** 关闭按钮 */
		CloseButton: ReactElement | false;
		/** 关闭按钮属性 */
		CloseButtonProps: ButtonProps;
		/** 弹窗宽度 */
		width: string | number;
		/** 弹窗高度 */
		height: string | number;
		/** 弹窗位置 */
		position: "left" | "top" | "right" | "bottom" | "center";
		/** 是否全屏 */
		fullscreen: boolean;
		/** 弹窗类型 message - 无按钮 alert - 一个按钮 confirm - 两个按钮 */
		type: "alert" | "confirm" | "message";
		/** 滚动主题 body - 网页内滚动 self - 弹窗主体内滚动 */
		scrollbody: "body" | "self";
		/** 取消回调，回调参数 (done:异步关闭回调，调用后弹窗才会关闭 , reason: overlay - 点击蒙层关闭 esc - 按压esc键关闭 router - 路由切换关闭 close - 关闭按钮关闭 cancel - 取消按钮关闭 ) */
		onCancel: (done: () => any, T: Reason) => void;
		/** 确认回调 回调参数 (done:异步关闭回调，调用后弹窗才会关闭 )*/
		onConfirm: (done: () => any) => void;
		/** 是否自动关闭，true - 自动关闭 confirm - 仅确定时按钮自动关闭 cancel - 仅取消时自动关闭，false - 手动关闭 */
		autoClose?: boolean;
	}>;

export default ModalProps;
