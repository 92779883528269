import BaseApi from "./BaseApi";
class LoanApi extends BaseApi {
  constructor() {
    super({
      // transformResponse: (req) => {
      //   const data = JSON.parse(req)
      //   if (data.code === 0) {
      //     return {
      //       ...data,
      //       code: 200,
      //     }
      //   }
      //   return data
      // }
    });
  }

  // 查询借款系统总览接口 /loan/open/overview
  overview() {
    return this.get('/api/loan/open/overview')
  }

  //取出放贷金额接口
  takeOutLend() {
    return this.post('/api/loan/loanlend/takeOutLend')
  }
  //取出放贷金额接口
  receiveFee() {
    return this.post('/api/loan/loanlend/receiveFee')
  }
  //领取违约金收益接口
  receiveDamages() {
    return this.post('/api/loan/loanlend/receiveDamages')
  }
  //查询全站借款记录接口
  queryLoanRecord({current,size,queryType}:any) {
    return this.get(`/api/loan/loanlend/queryLoanRecord?current=${current}&size=${size}&queryType=${queryType}`,)
  }
  //查询借放贷最高金额接口
  queryLoanLendMax({modelType,type}:any) {
    return this.get(`/api/loan/loanlend/queryLoanLendMax?modelType=${modelType}&type=${type}`)
  }
  //放贷接口
  doLending({lendAmount}:any) {
    return this.post(`/api/loan/loanlend/doLending?lendAmount=${lendAmount}`)
  }
  //准备贷款接口
  loanlendpreLoan({lendAmount}:any) {
    return this.get(`/api/loan/loanlend/preLend?lendAmount=${lendAmount}`)
  }
  //查询放贷详情接口
  queryLendDetail() {
    return this.get('/api/loan/loanlend/queryLendDetail')
  }
  //查询借放贷配置接口
  queryLoanLendConfig() {
    return this.get('/api/loan/loanlend/queryLoanLendConfig')
  }
  
  //查询放贷资格接口
  queryLendQualifications() {
    return this.get('/api/loan/loanlend/queryLendQualifications')
  }
  
  
  //查询借款系统总览接口
  queryOverview() {
    return this.get('/api/pd/missions-center/open/loanlend/queryOverview')
  }
  
  //贷款接口
  doLoan(params:any) {
    return this.post('/api/loan/loanlend/doLoan',params,{jsonType:true})
  }
  //准备贷款接口
  preLoan({lendAmount,modelType}:any) {
    return this.get(`/api/loan/loanlend/preLoan?lendAmount=${lendAmount}&modelType=${modelType}`)
  }

  //查询借款详情接口
  queryLoanDetail({loanAmount,modelType}:any) {
    return this.get(`/api/loan/loanlend/queryLoanDetail?loanAmount=${loanAmount}&modelType=${modelType}`)
  }
  //还款接口
  doRepayment(params:any) {
    return this.post('/api/loan/loanlend/doRepayment',params,{jsonType:true})
  }  

  //查询个人借贷记录接口
  queryLendRecord({current,size,queryType}:any) {
    return this.get(`/api/loan/loanlend/queryLendRecord?current=${current}&size=${size}&queryType=${queryType}`,)
  }
  
}
export default LoanApi;