
import { memberStore } from "@/stores";
import BaseApi from "./BaseApi";
export default class Texasapi extends BaseApi {
	constructor() {
		super({});
	}
  swapTradeInfo = ({ trade, token }: Record<string, string>) => {
    // return this.get(`/api/pd/missions-center/open/swap/trade-pair/info/${trade}/${token}`)
    return this.get(`/j9bc/swap/open/trade-pair/info/${trade}/${token}`)
  }
}
type Token = "AXS" | "BNB" | "DOGE" | "J9BC" | "LTC" | "LUNA" | "SHIB" | "USDT";