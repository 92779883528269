import clsx, { ClassValue } from "clsx";

const prefix = "j9bc-";
function modify(element: string, ...classes: ClassValue[]) {
	element = element + "--";

	let cls = clsx(...classes)
		.split(" ")
		.join(" " + element);		

	return cls ? " " + element + cls : "";
}

export default function createBEM(ns: string) {
	bem.next = function (child: string) {
		return createBEM(ns + "__" + child);
	};
	bem.raw = clsx;
	bem.ns = prefix + ns;

	function bem(e: string | null | undefined = "", m: ClassValue = "") {
		let cls = prefix + ns + (e ? "__" + e : "");
		if (m) cls += modify(cls, m);		
		return cls;
	}

	return bem;
}
