import { appStore } from "@/stores";
import BaseApi, { ApiConfig } from "./BaseApi";

class CmsApi extends BaseApi {
	constructor() {
		super({});
	}
	/**
	 * 查询CMS模块
	 * 暂时只返回常用模块,有需要请手动扩展字段
	 * @param modal 模块名
	 * @returns 结果
	 * 读取h86
	 */
	async getCmsResult(moduleCodes: string | number, config?: ApiConfig): Promise<Array<CMSModuleType> | []> {
		config = {
			...config,
			params: { moduleCodes, country: "CN", ...config?.params },
			headers: {
				"websiteCode": "H86_PC",
				"x-website-code": "H86_PC",
				"PRODUCT-ID": "H86",
				//@ts-ignore
				// "Accept-Language": __J9BC__.lang,
				"Accept-Language": 'cn',
				...config?.headers,
			},
		};
		const data = await this.get("/api/gateway/cms/page/templates", config);
		if (!data) return [];
		// 优先使用webp格式,cms未成功生产webp格式则跳过
		const flag = appStore.usableWebp;

		data.forEach((item: CMSModuleType) => {
			if (flag) {
				item.maxImageHttpUrl = item.maxImageHttpUrlWebp.length > 0 ? item.maxImageHttpUrlWebp : item.maxImageHttpUrl;
				item.minImageHttpUrl = item.minImageHttpUrlWebp.length > 0 ? item.minImageHttpUrlWebp : item.minImageHttpUrl;
				item.hotIconHttpUrl = item.minImageHttpUrlWebp.length > 0 ? item.hotIconHttpUrlWebp : item.hotIconHttpUrl;
			}
			item.target = item.targetType === "target" ? "_blank" : undefined;
		});
		return data;
	}
}

export default CmsApi;

interface CMSModuleType {
	target?: string;
	beginTime: string;
	closeWindowType: string | number;
	defaultAction: string;
	endTime: string;
	hotIcon: string;
	hotIconHttpUrl: string;
	hotIconHttpUrlWebp: string;
	id: string;
	isWhite: string | number;
	jsonObj: string;
	maxImage: string;
	maxImageAction: string;
	maxImageHttpUrl: string;
	maxImageHttpUrlWebp: string;
	minImage: string;
	minImageAction: string;
	minImageHttpUrl: string;
	minImageHttpUrlWebp: string;
	moduleCode: string;
	ossType: null;
	pageModuleId: string;
	productId: string;
	rank: string | number;
	status: string | number;
	targetType: string;
	templateButtons: TemplateButton[];
	templateType: any;
	textDescription: string;
	tip: string;
	userLevelStr: string;
	title: string;
	promotionDescription: string;
	detailAction: string;
}

//cms常规模块
interface TemplateButton {
	buttonAction: string;
	buttonImage: string;
	buttonImageHttpUrl: string;
	buttonImageWebp: string;
	buttonName: string;
	id: string;
	ossType: any;
	pageTemplateId: string;
	productId: string;
	rank: string | number;
	status: string | number;
}
