import React from "react";
import { ModalName } from ".";
import { Modal } from "@component/Modal";

export const DialogModal = <Modal className="Dialog-action" title={false} isOpen></Modal>;
const Modals = [
	/** demo*/
	{
		modalName: ModalName.Abc,
		component: DialogModal,
	},
];

export default Modals;
