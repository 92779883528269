/**
 * ### 普通接口配置项
 * * TIMEOUT - 接口请求超时时间: 5秒钟
 * * WITH_CREDENTIALS - 跨域请求是否提供凭据信息(cookie、HTTP认证及客户端SSL证明等)
 */
 export const ApiConst = {
  /** 接口请求超时时间: 5秒钟 */
  TIMEOUT: 60000,
  /** 跨域请求是否提供凭据信息(cookie、HTTP认证及客户端SSL证明等) */
  WITH_CREDENTIALS: false,
  /** 最大重定向次数 */
  MAX_REDIRECTS: 5,
};

/** 需要出发登录操作的code */
export const NET_ERROR_NEED_LOGIN = [
  // 401 未认证
  401,
  // 5001 您尚未登录或者登录已超时，请重新登录
  5001
];

/** 公共错误code */
export const NET_ERROR_COMMON = [
  ...NET_ERROR_NEED_LOGIN,
  // 请求传参有误
  400,
  // 未认证
  401,
  // 禁止访问
  403,
  // 资源不存在
  404,
  // 服务器内部错误
  500,
];
