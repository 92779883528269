const object2Entries = (
  source: Object,
  parentKey: string | null = null
) => {
  if (!source) {
    return [];
  }

  const sourceEntries = Object.entries(source);

  if (!sourceEntries || !sourceEntries.length) {
    return [];
  }

  const entries: any[] = [];
  sourceEntries.forEach(([k, v]) => {
    const key = `${parentKey ? `${parentKey}.` : ''}${k}`;
    if (
      v
      &&
      v.toString() === '[object Object]'
      &&
      Object.keys(v).length
    ) {
      entries.push(...object2Entries(v, key));
    } else {
      entries.push([key, v]);
    }
  })
  return entries;
}

const flatObject = (source: Object): Record<string, string> => {
  const obj: Record<string, string> = {};
  object2Entries(source).forEach(
    ([key, value]) => obj[key] = value
  );

  return obj;
}

export default flatObject
